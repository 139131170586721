import React from 'react';
import { Link } from 'react-router-dom';
import { LinkedinIcon, TwitterIcon } from '../icons';
import NavLogo from './NavLogo';
import MediaQuery from './MediaQuery';

const Footer: React.FC = () => (
  <footer className="container grid grid-cols-[min-content,min-content,1fr] items-center gap-4xl py-[6.4rem] xs:py-48">
    <div className="xs:col-start-1 xs:col-end-4">
      <MediaQuery view="lg">{lg => <NavLogo size={lg ? 1 : 1.15} />}</MediaQuery>
    </div>
    <div className="flex items-center gap-lg xs:gap-3xl">
      <a href="https://twitter.com/mithrl_ai" target="_blank" rel="nofollow">
        <TwitterIcon className="h-[2.5rem] w-[2.4rem] text-gray-400" />
      </a>
      <a href="https://www.linkedin.com/company/mithrl-ai/" target="_blank" rel="nofollow">
        <LinkedinIcon className="h-[2.5rem] w-[2.4rem] text-gray-400" />
      </a>
    </div>
    <div className="ml-auto flex items-center gap-3xl xs:col-start-2 xs-md:col-end-4">
      <Link to="/privacy" className="text-sm leading-normal text-gray-600 xs:underline">
        Privacy <span className="xs-md:hidden"> Policy</span>
      </Link>
      <Link to="/terms" className="text-sm leading-normal text-gray-600 xs:underline">
        Terms<span className="xs-md:hidden"> of Service</span>
      </Link>
      <Link to="/cookie-policy" className="text-sm leading-normal text-gray-600 xs:underline">
        Cookies<span className="xs-md:hidden"> Settings</span>
      </Link>
    </div>
  </footer>
);

export default Footer;
