import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import Typography from '../Typography';
import { ChevronLeftIcon } from '../../icons';
import MediaQuery from '../MediaQuery';
import clx from '../../lib/classList';
import Main from '../Main';
import ScrollAnimation from 'react-animate-on-scroll';
import ReactMarkdown from 'react-markdown';

const Article: React.FC = () => {
  // Get the article ID from the URL parameters
  const { id } = useParams<{ id: string }>();
  // State to store the article data
  const [article, setArticle] = useState<any>(null);

  // Load the article data dynamically based on the article ID
  useEffect(() => {
    const loadArticle = async () => {
      try {
        const articleData = await import(`../../data/articles/${id}`);
        setArticle(articleData.default);
      } catch (error) {
        console.error("Article not found:", error);
        setArticle(null);
      }
    };
    loadArticle();
  }, [id]);

  // If no article is found, show a fallback message
  if (!article) {
    return <p>Article not found</p>;
  }

  // Helper function to format the authors list
  const formatAuthors = (authors: string[]) => {
    if (authors.length === 2) {
      return authors.join(" and ");
    } else if (authors.length > 2) {
      return `${authors.slice(0, -1).join(", ")}, and ${authors[authors.length - 1]}`;
    }
    return authors[0];
  };

  return (
    <Main className="main-default">
      <div className="main-default container py-6xl lg:pt-6xl xs-md:pt-[1.8rem]">
        <ScrollAnimation animateIn="fadeInUp" animateOnce>
          {/* Header section with a back link and article title */}
          <div className="grid items-start lg:grid-cols-[1fr,72rem,1fr]">
            <Link
              to="/news"
              className="flex items-center gap-sm text-purple-600 hover:text-purple-700 lg:ml-md lg:mt-xxs xs-md:-ml-md"
            >
              <ChevronLeftIcon className="h-[2rem] w-[2.1rem]" />
              <Typography tag="span" variant="text-sm" className="font-semibold text-inherit">
                Back
              </Typography>
            </Link>
            <div className="grid gap-lg xs-md:pt-3xl">
              {/* Display 'News' title conditionally based on screen size */}
              <MediaQuery view="xs">
                {(xs) =>
                  xs ? (
                    <Typography tag="h3" variant="text-sm" className="font-semibold">
                      News
                    </Typography>
                  ) : (
                    <h3 className="text-base leading-normal text-gray-400">News</h3>
                  )
                }
              </MediaQuery>
              {/* Main article title */}
              <Typography tag="h1" variant="display-lg" mobile="display-md">
                {article.title}
              </Typography>
            </div>
          </div>

          {/* Hero image section */}
          <div className="flex flex-col justify-center pt-4xl md:pt-4xl xs:pt-[4.8rem]">
            <div
              className="xs:-mx-18 h-[40rem] bg-cover bg-center lg:bg-contain xs:h-[24rem]"
              style={{ backgroundImage: `url(${article.imageUrl})` }}
            />
          </div>

          

          {/* Main content section - maps over each section of the article */}
          <div className="mx-auto lg:w-[72rem] lg:pt-64">
            {/* Authors section - displayed after the hero image */}
          {article.authors && (
            <div className="mx-auto lg:w-[72rem] lg:pt-6 text-gray-500 text-sm mb-16">
              <Typography tag="p" variant="text-sm" className="text-gray-500">
                By {formatAuthors(article.authors)}
              </Typography>
            </div>
          )}
            {article.sections.map((section: any, index: number) => (
              <div key={index} className="mb-8">
                {/* Section heading */}
                <Typography tag="h2" variant="display-xs" className="mb-4">
                  {section.heading}
                </Typography>
                
                {/* Section text rendered with Markdown for formatting (e.g., bold, italics) */}
                <ReactMarkdown className="text-gray-300 text-lg mobile:text-md">
                  {section.text}
                </ReactMarkdown>

                {/* Optional link within the section */}
                {section.linkText && section.linkUrl && (
                  <a href={section.linkUrl} className="text-purple-600 hover:underline mt-2 inline-block">
                    {section.linkText}
                  </a>
                )}

                {/* Optional image within the section */}
                {section.image && (
                  <div className="pt-48 xs:pt-[4rem] text-center">
                    <img src={section.image.src} alt={section.image.alt} loading="lazy" className="mx-auto"/>
                    {/* Optional caption for the section image */}
                    {section.image.caption && (
                      <Typography tag="p" variant="text-sm" className="mt-2 text-gray-500">
                        {section.image.caption}
                      </Typography>
                    )}
                  </div>
                )}
              </div>
            ))}
          </div>
        </ScrollAnimation>
      </div>
    </Main>
  );
};

export default Article;
