import React from 'react';
import Typography from '../Typography';
import Particles from '../Particles';
import Tracer from '../Tracer';
import { PlayIcon } from '../../icons';
import clx from '../../lib/classList';
import ScrollAnimation from 'react-animate-on-scroll';
import Cta from '../Cta';

const HomeHero: React.FC = () => (
  <>
    <Particles />
    <div className="tight-container z-10 pb-4xl pt-[4.8rem] text-center md:px-[1.2rem]">
      <ScrollAnimation animateIn="fadeInUp" animateOnce delay={250}>
        <div className="flex items-center justify-center">
          <Tracer className="welcome-tracer">Welcome to Generative Biology</Tracer>
        </div>
        <h1
          className={clx(
            'pt-3xl text-5xl font-semibold leading-[1.2] tracking-tight text-white',
            'xs:pt-2xl xs:text-4xl xs:tracking-normal',
          )}
        >
          Great Scientists <br />
          <span className="gradient-text">
            should do <br className="md-lg:hidden" /> Great Science
          </span>
        </h1>
        <Typography tag="h3" variant="text-xl" mobile="text-lg" className="mt-2xl font-normal text-white lg:px-2xl">
          Labs waste weeks to learn and code pipelines that are obsolete for the next experiment. Mithrl automates the
          NGS Data Lifecycle using autonomous AI-powered agents –{' '}
          <span className="gradient-border inline-flex font-semibold">in minutes.</span>
        </Typography>
        <Typography
          tag="h3"
          variant="text-xl"
          mobile="text-lg"
          className="animated-gray-text mt-2xl font-normal xs:mt-xl"
        >
          Focus all your time conducting higher-quality experiments
        </Typography>
        <div className="flex justify-center pt-4xl">
          <Cta
            data-cal-namespace="mithrl-demo"
            data-cal-link="mithrl/mithrl-demo"
            data-cal-config='{"layout":"month_view","theme":"dark"}'
            size="lg"
            variant="secondary"
          >
            <PlayIcon className="h-[2.1rem] w-[2rem]" />
            Book a Demo
          </Cta>
        </div>
      </ScrollAnimation>
    </div>
  </>
);

export default HomeHero;
