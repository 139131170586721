import React, { Dispatch, SetStateAction } from 'react';
import {
  useFloating,
  useDismiss,
  useRole,
  useClick,
  useInteractions,
  FloatingFocusManager,
  FloatingOverlay,
  FloatingPortal,
} from '@floating-ui/react';
import NavLogo from './NavLogo';
import { CloseIcon } from '../icons';
import MenuItems from './MenuItems';
import Button from './Button';
import MediaQuery from './MediaQuery';
import Cta from './Cta';

type MenuProps = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
};

const Menu: React.FC<MenuProps> = ({ open, setOpen }) => {
  const { refs, context } = useFloating({
    open,
    onOpenChange: setOpen,
  });

  const click = useClick(context);
  const role = useRole(context);
  const dismiss = useDismiss(context, { outsidePressEvent: 'mousedown' });

  const { getFloatingProps } = useInteractions([click, role, dismiss]);

  return (
    <FloatingPortal>
      {open && (
        <FloatingOverlay lockScroll className="z-[1000] bg-[rgba(0,0,0,.5)] backdrop-blur-sm">
          <FloatingFocusManager context={context}>
            <div
              ref={refs.setFloating}
              {...getFloatingProps()}
              className="flex max-h-screen flex-col overflow-hidden bg-gray-900"
            >
              <div className="flex items-center justify-between p-xl pr-[1.2rem] md:pl-4xl md:pr-[2.8rem]">
                <MediaQuery view="lg">{lg => <NavLogo size={lg ? 1 : 1.15} />}</MediaQuery>
                <button className="p-md md:-mr-xs" onClick={() => setOpen(false)}>
                  <CloseIcon className="h-[2.4rem] w-[2.4rem] text-gray-200" />
                </button>
              </div>
              <div className="min-h-none flex-1 overflow-auto py-3xl">
                <MenuItems />
              </div>
              <div className="grid gap-lg border-t border-stone-700 px-xl py-[2.4rem] md:px-4xl">
                <Cta variant="primary" tag="link" to="https://login.mithrl.com" target="_blank">
                  Log in
                </Cta>
                <Cta
                  data-cal-namespace="mithrl-demo"
                  data-cal-link="mithrl/mithrl-demo"
                  data-cal-config='{"layout":"month_view","theme":"dark"}'
                >
                  Book a Demo
                </Cta>
              </div>
            </div>
          </FloatingFocusManager>
        </FloatingOverlay>
      )}
    </FloatingPortal>
  );
};

export default Menu;
