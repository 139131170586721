import React from 'react';
import { Link } from 'react-router-dom';
import clx from '../lib/classList';

type ButtonProps = React.ComponentProps<'button'>;
type AProps = React.ComponentProps<'a'>;
type LinkProps = React.ComponentProps<typeof Link>;

export type CtaProps = {
  variant?: 'default' | 'primary' | 'secondary';
  size?: 'md' | 'lg';
} & (
  | ({
      tag: 'link';
    } & LinkProps)
  | ({
      tag?: 'button';
    } & ButtonProps)
  | ({
      tag: 'a';
    } & AProps)
);

const Cta: React.FC<CtaProps> = ({ variant = 'default', size = 'md', ...props }) => {
  const classList = clx(
    ' font-semibold rounded-lg shadow-sm transition-all duration-250 active:cursor-default text-white flex items-center gap-sm justify-center',
    {
      'leading-5 py-[1rem] px-[1.4rem] text-sm': size === 'md',
      'py-lg px-[1.8rem] text-base leading-normal': size === 'lg',
      'bg-purple-600 hover:bg-purple-800 active:bg-purple-800 active:shadow-md': variant === 'default',
      'bg-gray-800 outline outline-2 outline-gray-700 hover:bg-gray-700 active:bg-gray-900 active:shadow-[0_0.1rem_0.2rem_0_rgba(16,24,40,0.05),0_0_0_0.4rem_rgba(148,150,156,0.14)]':
        variant === 'primary',
      'outline outline-2 outline-gray-300 bg-white text-gray-900 hover:bg-gray-300 active:bg-white active:shadow-[0_0.1rem_0.2rem_0_rgba(16,24,40,0.05),0_0_0_0.4rem_rgba(152,162,179,0.14)]':
        variant === 'secondary',
    },
  );

  switch (props.tag) {
    case 'a': {
      const { tag, className, ...rest } = props;
      return <a className={clx(classList, className)} {...rest} />;
    }

    case 'link': {
      const { tag, className, ...rest } = props;
      return <Link className={clx(classList, className)} {...rest} />;
    }

    default: {
      const { tag, className, ...rest } = props;
      return <button className={clx(classList, className)} {...rest} />;
    }
  }
};

export default Cta;
