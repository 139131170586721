import React from 'react';
import clx from '../lib/classList';
import MediaQuery from './MediaQuery';

type Variant =
  | 'display-md'
  | 'text-md'
  | 'display-lg'
  | 'display-sm'
  | 'text-xl'
  | 'text-lg'
  | 'text-sm'
  | 'text-xs'
  | 'display-xs';

type TypographyProps = React.ComponentProps<'h1'> & {
  tag: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'p' | 'span';
  variant: Variant;
  mobile?: Variant;
};

const Typography: React.FC<TypographyProps> = ({ className, children, variant, mobile = variant, tag, ...props }) => {
  const TagName = tag;

  return (
    <MediaQuery view="xs">
      {md => (
        <TagName
          className={clx(
            'font-poppins',
            {
              'text-5xl font-semibold leading-tight tracking-tight text-white':
                (md && mobile === 'display-lg') || variant === 'display-lg',

              'text-xl font-medium leading-normal text-gray-400': (md && mobile === 'text-xl') || variant === 'text-xl',

              'text-4xl font-semibold leading-[4.4rem] tracking-normal text-white':
                (md && mobile === 'display-md') || variant === 'display-md',

              'text-lg leading-[2.8rem] text-gray-400': (md && mobile === 'text-lg') || variant === 'text-lg',

              'text-3xl font-semibold leading-[3.8rem] tracking-wide text-white':
                (md && mobile === 'display-sm') || variant === 'display-sm',

              'text-base leading-normal text-gray-400': (md && mobile === 'text-md') || variant === 'text-md',

              'text-sm leading-5 text-gray-400': (md && mobile === 'text-sm') || variant === 'text-sm',

              'text-xs leading-5 text-gray-400': (md && mobile === 'text-xs') || variant === 'text-xs',

              'text-2xl font-semibold leading-8 text-white':
                (md && mobile === 'display-xs') || variant === 'display-xs',
            },
            className,
          )}
        >
          {children}
        </TagName>
      )}
    </MediaQuery>
  );
};

export default Typography;
