import React from 'react';
import { NavLink } from 'react-router-dom';
import clsx from '../lib/classList';

const MENU_ITEMS = [
  {
    label: 'Product',
    href: '/',
  },
  {
    label: 'Pricing',
    href: '/pricing',
  },
  {
    label: 'About us',
    href: '/about',
  },
  {
    label: 'News',
    href: '/news',
  },
];

const MenuItems: React.FC = () => (
  <ul className="flex gap-4xl lg:items-center xs-md:flex-col xs-md:gap-md">
    {MENU_ITEMS.map(item => (
      <li key={item.label} className="md:px-4xl xs:px-xl xs-md:py-lg">
        <NavLink
          className={({ isActive }) =>
            clsx('duration-15 lg:typography-text-sm xs-md:typography-text-md font-semibold transition-all', {
              'cursor-default text-gray-50': isActive,
              'text-gray-600 hover:text-gray-400': !isActive,
            })
          }
          to={item.href}
        >
          {item.label}
        </NavLink>
      </li>
    ))}
  </ul>
);

export default MenuItems;
