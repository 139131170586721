import React from 'react';
import Main from '../components/Main';
import Typography from '../components/Typography';

const PrivacyPage: React.FC = () => {
  return (
    <Main className="main-default">
      <div className="container mx-auto px-4 py-8">
        <Typography tag="h1" variant="display-md" mobile="display-md">
          Mithrl Privacy Policy
        </Typography>
        <Typography tag="span" variant="text-xs" className="block italic">
          Effective date: July 10, 2024
        </Typography>

        <Typography tag="p" variant="text-sm" className="my-4 text-white">
          This Privacy Policy describes how Mithrl Inc. (“Mithrl”) collects, uses and discloses information, and what
          choices you have with respect to the information.‍
        </Typography>

        <ol className="list-inside list-decimal">
          <Typography variant="text-md" tag="h3" className="my-4">
            <li>Scope of this Privacy Policy</li>
          </Typography>
          <Typography tag="p" variant="text-sm" className="my-4 text-white">
            This Privacy Policy applies to Mithrl’s products and services, including applicable mobile and desktop
            applications (collectively, the “Services”), Mithrl.com and other Mithrl websites (collectively, the
            “Websites”) and other interactions (e.g., customer service inquiries, user conferences, etc.) you may have
            with Mithrl. If you do not agree with the terms, do not access or use the Services, Websites, or any other
            aspect of Mithrl’s business.
          </Typography>
          <Typography tag="p" variant="text-sm" className="my-4 text-white">
            This Privacy Policy does not apply to any third-party applications or software that integrate with the
            Services through the Mithrl platform (“Third-Party Services”), or any other third-party products, services
            or businesses. In addition, a separate agreement governs delivery, access, and use of the Services (the
            “MSA”), including the processing of any data submitted through the Services (“Service Data”). The
            organization (e.g., your employer or another entity or person) that agreed to the MSA (“Customer”) controls
            its instance of the Services and any associated Service Data (the “Customer Instance”).
          </Typography>
          <Typography variant="text-md" tag="h3" className="my-4">
            <li>Information Mithrl Collects and Receives</li>
          </Typography>
          <Typography tag="p" variant="text-sm" className="my-4 text-white">
            Mithrl may collect, generate, and receive Service Data and other information and data (“Other Information”;
            Service Data and Other Information collectively “Information”)) in a variety of ways:
          </Typography>
          <ul className="mt-2 list-inside list-disc ps-5">
            <li>
              <Typography variant="text-md" tag="span" className="mr-2">
                Service data.
              </Typography>{' '}
              <Typography variant="text-md" tag="span" className="mr-2 text-white">
                Customers and individuals granted access to a Customer Instance by a Customer (“Authorized Users”) may
                submit Service Data to Mithrl when using the Services.
              </Typography>
            </li>
            <li>
              <Typography variant="text-md" tag="span" className="mr-2">
                Other information.
              </Typography>

              <ul className="mt-2 list-inside list-disc ps-5">
                <li>
                  <Typography variant="text-md" tag="span" className="mr-2">
                    Account information.
                  </Typography>{' '}
                  <Typography variant="text-md" tag="span" className="mr-2 text-white">
                    To create or update a Mithrl account, you or a Customer (e.g., your employer) supply Mithrl with an
                    email address, phone number, password, domain and/or similar account details. In addition, Customers
                    that purchase a paid version of the Services provide Mithrl (or its payment processors) with billing
                    details such as credit card information, banking information and/or a billing address.
                  </Typography>
                </li>
                <li>
                  <Typography variant="text-md" tag="span" className="mr-2">
                    Usage information.
                  </Typography>

                  <ul className="mt-2 list-inside list-disc ps-5">
                    <li>
                      <Typography variant="text-md" tag="span" className="mr-2">
                        Services metadata.
                      </Typography>{' '}
                      <Typography variant="text-md" tag="span" className="mr-2 text-white">
                        When an Authorized User interacts with the Services, metadata is generated that provides
                        additional context about the way Authorized Users interact with the Services. For example,
                        Mithrl logs what Third Party Services are connected with the Services (if any).
                      </Typography>
                    </li>
                    <li>
                      <Typography variant="text-md" tag="span" className="mr-2">
                        Log data.
                      </Typography>{' '}
                      <Typography variant="text-md" tag="span" className="mr-2 text-white">
                        As with most technology services delivered over the Internet, our servers automatically collect
                        information when you access or use our Websites or Services and record it in log files. This log
                        data may include the Internet Protocol (IP) address, the address of the web page visited before
                        using the Website or Services, browser type and settings, the date and time the Services were
                        used, information about browser configuration and plugins, language preferences and cookie data.
                      </Typography>
                    </li>
                    <li>
                      <Typography variant="text-md" tag="span" className="mr-2">
                        Device information.
                      </Typography>{' '}
                      <Typography variant="text-md" tag="span" className="mr-2 text-white">
                        Mithrl collects information about devices accessing the Services, including type of device, what
                        operating system is used, device settings, application IDs, unique device identifiers and crash
                        data. Whether Mithrl collects some or all of this information often depends on the type of
                        device used and its settings.
                      </Typography>
                    </li>
                    <li>
                      <Typography variant="text-md" tag="span" className="mr-2">
                        Location information.
                      </Typography>{' '}
                      <Typography variant="text-md" tag="span" className="mr-2 text-white">
                        Mithrl receives information from you, your Customer and other third parties that may help Mithrl
                        approximate your location. Mithrl may, for example, use a business address submitted by your
                        employer, or an IP address received from your browser or device to determine approximate
                        location. Mithrl may also collect location information from devices in accordance with the
                        consent process provided by your device.
                      </Typography>
                    </li>
                    <li>
                      <Typography variant="text-md" tag="span" className="mr-2">
                        Cookie information.
                      </Typography>{' '}
                      <Typography variant="text-md" tag="span" className="my-4 text-white">
                        Mithrl uses cookies and similar technologies in our Websites and Services to help us collect
                        Other Information. The Websites and Services may also include cookies and similar tracking
                        technologies of third parties, which may collect Other Information about you via the Websites
                        and Services and across other websites and online services. For more details about how Mithrl
                        uses these technologies, and your opt-out opportunities and other options, please see{' '}
                        <a className="text-[#AB22FF]" href="/cookie-policy">
                          Mithrl’s Cookie Policy.
                        </a>
                      </Typography>
                    </li>
                  </ul>
                </li>

                <li>
                  <Typography variant="text-md" tag="span" className="mr-2">
                    Third-Party Services.
                  </Typography>{' '}
                  <Typography variant="text-md" tag="span" className="my-4 text-white">
                    A Customer can connect Third-Party Services to its Customer Instance. Typically, Third-Party
                    Services are software services that integrate with Mithrl Services, and a Customer can permit its
                    Authorized Users to enable and disable these integrations for its Customer Instance. Mithrl may also
                    develop and offer Mithrl applications that connect the Services with a Third-Party Service. Once
                    enabled, the provider of a Third-Party Service may share certain information with Mithrl. For
                    example, if a single sign-on service is connected with Mithrl, Mithrl may receive the username and
                    email address of Authorized Users, along with additional information that the application has
                    elected to make available to Mithrl to facilitate the integration. Authorized Users should check the
                    privacy settings and notices in these Third-Party Services to understand what data may be disclosed
                    to Mithrl. When a Third-Party Service is enabled, Mithrl is authorized to connect and access Other
                    Information made available to Mithrl in accordance with any permission(s) granted by Customer
                    (including, by its Authorized User(s)). Mithrl does not, however, receive or store passwords for any
                    of these Third-Party Services when connecting them to the Services.
                  </Typography>
                </li>
                <li>
                  <Typography variant="text-md" tag="span" className="mr-2">
                    Contact information.
                  </Typography>{' '}
                  <Typography variant="text-md" tag="span" className="my-4 text-white">
                    An Authorized User is required to provide some contact information (e.g., an email address) when
                    making an account on the Services.
                  </Typography>
                </li>
                <li>
                  <Typography variant="text-md" tag="span" className="mr-2">
                    Third-party data.
                  </Typography>{' '}
                  <Typography variant="text-md" tag="span" className="my-4 text-white">
                    Mithrl may receive data about organizations, industries, lists of companies that are customers,
                    Website visitors, marketing campaigns and other matters related to our business from affiliates and
                    subsidiaries, our partners, or others that Mithrl engages with to make Mithrl’s own information
                    better or more useful. This data may be combined with Other Information Mithrl collects and might
                    include aggregate-level data, such as which IP addresses correspond to zip codes or countries. Or it
                    might be more specific: for example, how well an online marketing or email campaign performed.
                  </Typography>
                </li>
                <li>
                  <Typography variant="text-md" tag="span" className="mr-2">
                    Additional information provided to Mithrl.
                  </Typography>{' '}
                  <Typography variant="text-md" tag="span" className="my-4 text-white">
                    Mithrl receives Other Information when submitted to our Websites or in other ways, such as if you
                    participate in a focus group, contest, activity or event, apply for a job, enroll in an educational
                    program hosted by Mithrl or a vendor, request support, interact with our social media accounts or
                    otherwise communicate with Mithrl.
                  </Typography>
                </li>
              </ul>
            </li>
          </ul>
          <Typography variant="text-md" tag="span" className="mr-2">
            <li>How Mithrl Uses Information</li>
          </Typography>{' '}
          <Typography variant="text-md" tag="span" className="my-4 text-white">
            Service Data will be used by Mithrl in accordance with the applicable MSA, Customer’s use of Services
            functionality, and as required by applicable law. Mithrl is a processor of Service Data and Customer is the
            controller.
          </Typography>
          <Typography variant="text-md" tag="span" className="my-4 text-white">
            In addition, Mithrl uses Information in furtherance of our legitimate interests in operating our Services,
            Websites, and business. More specifically, Mithrl uses Information:
          </Typography>
          <ul className="mt-2 list-inside list-disc ps-5">
            <li>
              <Typography variant="text-md" tag="span" className="mr-2">
                To provide, update, maintain and protect our Services, Websites and business.
              </Typography>{' '}
              <Typography variant="text-md" tag="span" className="my-4 text-white">
                This includes use of Other Information to support delivery of the Services under a Customer’s account,
                prevent or address service errors, security or technical issues, analyze and monitor usage, trends and
                other activities or at an Authorized User’s request.
              </Typography>
            </li>
            <li>
              <Typography variant="text-md" tag="span" className="mr-2">
                To provide, update, maintain and protect our Services, Websites and business. As required by applicable
                law, legal process or regulation.
              </Typography>
            </li>
            <li>
              <Typography variant="text-md" tag="span" className="mr-2">
                To communicate with you by responding to your requests, comments and questions.
              </Typography>{' '}
              <Typography variant="text-md" tag="span" className="my-4 text-white">
                If you contact us, we may use your Other Information to respond.
              </Typography>
            </li>
            <li>
              <Typography variant="text-md" tag="span" className="mr-2">
                To develop and provide search, learning and productivity tools and additional features.
              </Typography>{' '}
              <Typography variant="text-md" tag="span" className="my-4 text-white">
                Mithrl tries to make the Services as useful as possible for specific Customer Instances and Authorized
                Users. For example, Mithrl may improve search functionality by using Other Information to help determine
                and rank the relevance of content, channels or expertise to an Authorized User, make Services
                suggestions based on historical use and predictive models, identify organizational trends and insights,
                to customize a Services experience or create new productivity features and products.
              </Typography>
            </li>
            <li>
              <Typography variant="text-md" tag="span" className="mr-2">
                To send emails and other communications.
              </Typography>{' '}
              <Typography variant="text-md" tag="span" className="my-4 text-white">
                We may send you service, technical and other administrative emails, messages and other types of
                communications. We may also contact you to inform you about changes in our Services, our Services
                offerings, and important Services-related notices, such as security and fraud notices. These
                communications are considered part of the Services and you may not opt out of them. In addition, we
                sometimes send emails about new product features, promotional communications or other news about Mithrl.
                These are marketing messages so you can control whether you receive them.
              </Typography>
            </li>
            <li>
              <Typography variant="text-md" tag="span" className="mr-2">
                For billing, account management and other administrative matters.
              </Typography>{' '}
              <Typography variant="text-md" tag="span" className="my-4 text-white">
                Mithrl may need to contact you for invoicing, account management and similar reasons and we use account
                data to administer accounts and keep track of billing and payments.
              </Typography>
            </li>
            <li>
              <Typography variant="text-md" tag="span" className="mr-2">
                To investigate and help prevent security issues and abuse.
              </Typography>{' '}
              <Typography variant="text-md" tag="span" className="my-4 text-white">
                If we think something unlawful or otherwise bad might be happening, we may inspect and analyze Other
                Information for security issues and abuse.
              </Typography>
            </li>
          </ul>
          <Typography variant="text-md" tag="h3" className="my-4">
            <li>Data Retention</li>
          </Typography>
          <Typography variant="text-md" tag="span" className="my-4 text-white">
            Mithrl will retain Personal Data in accordance with a Customer’s instructions, including any applicable
            terms in the MSA and Customer’s use of Services functionality, and as required by applicable law. Mithrl may
            retain Other Information pertaining to you for as long as necessary for the purposes described in this
            Privacy Policy. This may include keeping your Other Information after you have deactivated your account for
            the period of time needed for Mithrl to pursue legitimate business interests, conduct audits, comply with
            (and demonstrate compliance with) legal obligations, resolve disputes and enforce our agreements.
          </Typography>
          <Typography variant="text-md" tag="h3" className="my-4">
            <li>How Mithrl Shares and Discloses Information</li>
          </Typography>
          <Typography variant="text-md" tag="span" className="my-4 text-white">
            This section describes how Mithrl may share and disclose Information. Customers determine their own policies
            and practices for the sharing and disclosure of Information, and Mithrl does not control how they or any
            other third parties choose to share or disclose Information.
          </Typography>
          <Typography variant="text-md" tag="span" className="my-4 text-white">
            Mithrl may share and disclose Information in the following ways:
          </Typography>
          <ul className="mt-2 list-inside list-disc ps-5">
            <li>
              <Typography variant="text-md" tag="span" className="mr-2">
                Displaying and operating the Services.
              </Typography>{' '}
              <Typography variant="text-md" tag="span" className="my-4 text-white">
                Because of the nature and functionality of the Services, Information will be displayed as part of the
                Services to Authorized Users in a Customer Instance. For example, information about which of Customer’s
                employees may have two-factor authentication enabled may be displayed as part of the Services.
              </Typography>
            </li>
            <li>
              <Typography variant="text-md" tag="span" className="mr-2">
                Third-party service providers and partners.{' '}
              </Typography>{' '}
              <Typography variant="text-md" tag="span" className="my-4 text-white">
                Mithrl may engage third parties as service providers or business partners to process Information and
                support our business. These third parties may, for example, provide virtual computing and storage
                services. To the extent necessary and applicable, these third-party service providers and partners will
                be bound by appropriate and commercially reasonable confidentiality obligations.{' '}
              </Typography>
            </li>
            <li>
              <Typography variant="text-md" tag="span" className="mr-2">
                Third-Party Services
              </Typography>{' '}
              <Typography variant="text-md" tag="span" className="my-4 text-white">
                Customer may enable or permit Authorized Users to enable Third-Party Services. Mithrl requires each
                Third-Party Service to disclose all permissions for information access in the Services, but Mithrl does
                not guarantee that they do so. When enabled and as requested by Customer, Mithrl may share Information
                with Third-Party Services. Third-Party Services are not owned or controlled by Mithrl and third parties
                that have been granted access to Information may have their own policies and practices for its
                collection, use, and sharing. Please check the permissions, privacy settings, and notices for these
                Third-Party Services or contact the service provider for any questions.
              </Typography>
            </li>
            <li>
              <Typography variant="text-md" tag="span" className="mr-2">
                Corporate affiliates.
              </Typography>{' '}
              <Typography variant="text-md" tag="span" className="my-4 text-white">
                Mithrl may share Information with its corporate affiliates, parents, and/or subsidiaries.
              </Typography>
            </li>
            <li>
              <Typography variant="text-md" tag="span" className="mr-2">
                During a change to Mithrl’s business.
              </Typography>{' '}
              <Typography variant="text-md" tag="span" className="my-4 text-white">
                If Mithrl engages in a merger, acquisition, bankruptcy, dissolution, reorganization, sale of some or all
                of Mithrl’s assets or stock, financing, public offering of securities, acquisition of all or a portion
                of Mithrl’s business, a similar transaction or proceeding, or steps in contemplation of such activities,
                some or all Information may be shared or transferred, subject to appropriate and commercially reasonable
                confidentiality arrangements.
              </Typography>
            </li>
            <li>
              <Typography variant="text-md" tag="span" className="mr-2">
                To Comply with Laws.
              </Typography>{' '}
              <Typography variant="text-md" tag="span" className="my-4 text-white">
                If a law enforcement or government agency sends Mithrl a demand for Information about a Customer, Mithrl
                shall attempt to redirect the agency to request that data directly from the Customer. As part of this
                effort, Mithrl may provide the Customer’s basic contact information to the law enforcement or government
                agency. If compelled to disclose Information to a law enforcement or government agency, then Mithrl will
                give the Customer reasonable notice of the demand and cooperation to allow the Customer to seek a
                protective order or other appropriate remedy unless Mithrl is legally prohibited from doing so. Mithrl
                will not voluntarily disclose Information related to a Customer to any law enforcement or government
                agency.
              </Typography>
            </li>
            <li>
              <Typography variant="text-md" tag="span" className="mr-2">
                To enforce our rights, prevent fraud, and for safety.
              </Typography>{' '}
              <Typography variant="text-md" tag="span" className="my-4 text-white">
                To protect and defend the rights, property, or safety of Mithrl or third parties, including enforcing
                contracts or policies, or in connection with investigating and preventing fraud or security issues.
              </Typography>
            </li>
            <li>
              <Typography variant="text-md" tag="span" className="mr-2">
                With consent.
              </Typography>{' '}
              <Typography variant="text-md" tag="span" className="my-4 text-white">
                Mithrl may share Information with third parties when Mithrl has consent to do so.
              </Typography>
            </li>
          </ul>
          <Typography variant="text-md" tag="h3" className="my-4">
            <li>Security</li>
          </Typography>
          <Typography tag="p" variant="text-sm" className="my-4 text-white">
            Security is critical to Mithrl’s mission, and Mithrl takes security of data seriously. Mithrl uses
            industry-standard technical and organizational measures to protect Information from loss, misuse, and
            unauthorized access or disclosure. These steps take into account the sensitivity of the Information Mithrl
            collects, processes, and stores, and the current state of technology. Given the nature of communications and
            information processing technology, Mithrl cannot guarantee that Information in our care will be absolutely
            safe from intrusion by others during transmission through the Internet or while stored on our systems or
            otherwise. When you click a link to a third-party site, you will be leaving our site and Mithrl doesn’t
            control or endorse what is on third-party sites.
          </Typography>
          <Typography variant="text-md" tag="h3" className="my-4">
            <li>Age Limitations</li>
          </Typography>
          <Typography tag="p" variant="text-sm" className="my-4 text-white">
            To the extent prohibited by applicable law, Mithrl does not allow use of our Services and Websites by anyone
            younger than 16 years old. If Mithrl learns that anyone younger than 16 has unlawfully provided Personal
            Data, Mithrl will take steps to delete such information.
          </Typography>
          <Typography variant="text-md" tag="h3" className="my-4">
            <li>Changes to This Privacy Policy</li>
          </Typography>
          <Typography tag="p" variant="text-sm" className="my-4 text-white">
            Mithrl may change this Privacy Policy from time to time. Laws, regulations, and industry standards evolve,
            which may make those changes necessary, or Mithrl may make changes to our services or business. Mithrl will
            post the changes to this page and encourage you to review our Privacy Policy to stay informed. If Mithrl
            makes changes that materially alter your privacy rights, Mithrl will provide additional notice, such as via
            email or through the Services. If you disagree with the changes to this Privacy Policy, you should cease
            interacting with the Services. Contact the applicable Customer if you wish to request the removal of
            Personal Data under their control.
          </Typography>
          <Typography variant="text-md" tag="h3" className="my-4">
            <li>Identifying the Data Controller and Processor</li>
          </Typography>
          <Typography tag="p" variant="text-sm" className="my-4 text-white">
            Data protection law in certain jurisdictions differentiates between the “controller” and “processor” of
            information. In general, Customer is the controller of Service Data. In general, Mithrl is the processor of
            Service Data and the controller of Other Information.
          </Typography>
          <Typography variant="text-md" tag="h3" className="my-4">
            <li>Your Rights</li>
          </Typography>
          <Typography tag="p" variant="text-sm" className="my-4 text-white">
            Individuals located in certain US States such as California, Virginia, and countries, including those in the
            European Economic Area and the United Kingdom, have certain statutory rights in relation to their personal
            data. Subject to any exemptions provided by law, you may have the right to request access to Information (in
            a portable form or otherwise), as well as to seek to update, delete or correct this Information. You can
            exercise your privacy rights by sending a request to via email at: privacy@mithrl.com or in writing to:
          </Typography>
          <Typography tag="p" variant="text-sm" className="whitespace-pre-line text-white">
            Mithrl Inc.<br></br>
            ‍Attn: Data Privacy Officer<br></br>
            44 Montgomery St,<br></br>
            San Francisco, CA 94104
          </Typography>
          <Typography tag="p" variant="text-sm" className="my-4 text-white">
            To the extent that Mithrl’s processing of your Personal Data is subject to the General Data Protection
            Regulation or applicable laws covering the processing of Personal Data such as the UK Data Protection Act
            and the Brazilian General Data Protection Act (Lei Geral de Proteção de Dados), Mithrl relies on its
            legitimate interests, described above, to process your data. Mithrl may also process Other Information that
            constitutes your Personal Data for direct marketing purposes, and you have a right to object to Mithrl’s use
            of your Personal Data for this purpose at any time.
          </Typography>
          <Typography variant="text-md" tag="h3" className="my-4">
            <li>Your California Privacy Rights</li>
          </Typography>
          <Typography tag="p" variant="text-sm" className="my-4 text-white">
            California law permits residents of California to request certain details about how their information is
            shared with third parties for direct marketing purposes. Mithrl does not share your personally identifiable
            information with third parties.
          </Typography>
          <Typography variant="text-md" tag="h3" className="my-4">
            <li>Your Virginia Privacy Rights</li>
          </Typography>
          <Typography tag="p" variant="text-sm" className="my-4 text-white">
            Subject to certain limitations, the Virginia Consumer Data Protection Act (“VCDPA”) provides Virginia
            consumers the right to request to access personal information we collect about you, correct inaccuracies in
            you personal data, to request a portable copy of your personal information, and to delete your personal
            information. You also have the right opt-out from the processing your Personal Data for the purposes of
            profiling in furtherance of decisions that produce legal or similarly significant effects to you, which
            Mithrl does not do.
          </Typography>
          <Typography variant="text-md" tag="h3" className="my-4">
            <li>Data Protection Authority</li>
          </Typography>
          <Typography tag="p" variant="text-sm" className="my-4 text-white">
            Subject to applicable law, you also have the right to (i) restrict Mithrl’s use of Information that
            constitutes your Personal Data and (ii) lodge a complaint with your local data protection authority. If you
            are a resident of the European Economic Area and believe we maintain your Personal Data within the scope of
            the General Data Protection Regulation (GDPR), you may direct questions or complaints to privacy@mithrl.com{' '}
          </Typography>
          <Typography variant="text-md" tag="h3" className="my-4">
            <li>Contacting Mithrl</li>
          </Typography>
          <Typography tag="p" variant="text-sm" className="my-4 text-white">
            Please also feel free to contact Mithrl if you have any questions about this Privacy Policy or Mithrl’s
            practices, or if you are seeking to exercise any of your statutory rights. Mithrl will respond within a
            timeframe that is compliant with all applicable regulations. You may contact us at privacy@Mithrl.com or at
            our mailing address below:
          </Typography>
          <Typography tag="p" variant="text-sm" className="whitespace-pre-line text-white">
            Mithrl Inc.<br></br>
            ‍Attn: Data Privacy Officer<br></br>
            44 Montgomery St,<br></br>
            San Francisco, CA 94104
          </Typography>
        </ol>
      </div>
    </Main>
  );
};

export default PrivacyPage;
