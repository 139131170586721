import React, { useState } from 'react';
import { Dialog, DialogContent, DialogClose } from './Dialog';
import { X } from 'lucide-react';
import Cta from './Cta';

const API_KEY = 'patTdmF4Ld855MVuH.bc6ad3fcd17fa637b7483224d6ae4ce0cee7d052c77b7f0b231ed76205f17f3f'; // Replace with your Airtable API key
const BASE_ID = 'appOBn4QZ70wY8BMJ'; // Replace with your Airtable base ID
const TABLE_NAME = 'tblwEsAjnp4vqXkhR'; // Replace with your table name
const sendDataToAirtable = async (data: Record<string, string | number>) => {
  try {
    const response = await fetch(`https://api.airtable.com/v0/${BASE_ID}/${TABLE_NAME}`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${API_KEY}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ fields: data }),
    });

    return 'Form submitted successfully'; // Return success message
  } catch (error) {
    console.error('Error sending data:', error);
    return 'There was a problem with the form submission'; // Return error message
  }
};

export const ContactUsForm = ({ isOpen, onClose }: { isOpen: boolean; onClose: () => void }) => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [jobTitle, setJobTitle] = useState('');
  const [additionalInfo, setAdditionalInfo] = useState('');
  const [formMessage, setFormMessage] = useState('');
  const [isSuccessful, setIsSuccessful] = useState(false);

  const resetState = () => {
    setIsSuccessful(false);
    setFirstName('');
    setLastName('');
    setEmail('');
    setCompanyName('');
    setJobTitle('');
    setAdditionalInfo('');
    setFormMessage('');
  };

  const closeModal = () => {
    resetState();
    onClose && onClose();
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    const formData = {
      'First Name': firstName,
      'Last Name': lastName,
      Email: email,
      Organization: companyName,
      Role: jobTitle,
      Notes: additionalInfo,
      'How did you hear about us?': '',
    };

    try {
      const message = await sendDataToAirtable(formData);

      setFormMessage(message);

      if (message === 'Form submitted successfully') {
        resetState();
        setIsSuccessful(true);
        setFormMessage(message);
      }
    } catch (error) {
      console.error('Error handling form submission:', error);
    }
  };

  let content = null;
  if (formMessage) {
    content = (
      <div className="flex h-full w-full flex-col items-center justify-center gap-8 p-8">
        <div className="text-center text-lg text-white">
          {isSuccessful
            ? 'Thank you for your interest in Mithrl! Our team will get back to you shortly!'
            : "We're sorry, there was an error submitting your information. Please try again."}
        </div>
        <Cta
          onClick={closeModal}
          variant="primary"
          className="max-w-[400px] rounded-md bg-indigo-500 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
        >
          Close
        </Cta>
      </div>
    );
  } else {
    content = (
      <div className="flex h-full w-full xs:flex-col">
        <div className="h-full w-full border-r-[1px] border-[#404040] pr-4 xs:border-b-[1px] xs:border-r-[0px] xs:pb-4 xs:pr-0 md-lg:w-[30%]">
          <div className="flex h-full w-full flex-col">
            <div className="flex h-full w-full flex-col gap-2">
              <div className="flex h-fit w-fit items-center gap-2 pb-2 text-center">
                <img src="assets/icons/logo.svg" width={24} height={24} />
                <span className="text-sm text-[#A5A5A5]">Mithrl</span>
              </div>
              <span className="text-[20px] font-semibold text-[#FCFCFD]">Contact us</span>
              <span className="text-[14px] text-[#FCFCFD]">
                Looking to hear more? Fill out the form, and we’ll get back to you within 2 hours!
              </span>
            </div>
          </div>
        </div>
        <form onSubmit={handleSubmit} className="flex h-full w-full flex-col pl-8 xs:pl-0 xs:pt-8">
          <div className="w-full lg:mr-0">
            <div className="flex w-full flex-col gap-8 xs:gap-1">
              <div className="flex w-full gap-8 xs:flex-col xs:gap-1 xs:gap-3">
                <div className="w-full">
                  <label htmlFor="first-name" className="block text-sm font-semibold leading-6 text-white">
                    First name
                  </label>
                  <div className="mt-2.5 w-full xs:mt-0.5">
                    <input
                      required
                      type="text"
                      name="first-name"
                      id="first-name"
                      autoComplete="given-name"
                      value={firstName}
                      onChange={e => setFirstName(e.target.value)}
                      className="block w-full rounded-md border-0 bg-white/5 px-3.5 py-2 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
                <div className="w-full">
                  <label htmlFor="last-name" className="block text-sm font-semibold leading-6 text-white">
                    Last name
                  </label>
                  <div className="mt-2.5 w-full xs:mt-0.5">
                    <input
                      required
                      type="text"
                      name="last-name"
                      id="last-name"
                      autoComplete="family-name"
                      value={lastName}
                      onChange={e => setLastName(e.target.value)}
                      className="block w-full rounded-md border-0 bg-white/5 px-3.5 py-2 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
              </div>
              <div className="sm:col-span-2">
                <label htmlFor="email" className="block text-sm font-semibold leading-6 text-white">
                  Email
                </label>
                <div className="mt-2.5 w-full xs:mt-0.5">
                  <input
                    required
                    type="email"
                    name="email"
                    id="email"
                    autoComplete="email"
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                    className="block w-full rounded-md border-0 bg-white/5 px-3.5 py-2 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
              <div className="sm:col-span-2">
                <label htmlFor="company-name" className="block text-sm font-semibold leading-6 text-white">
                  Company/Organization
                </label>
                <div className="mt-2.5 w-full xs:mt-0.5">
                  <input
                    required
                    type="text"
                    name="company-name"
                    id="company-name"
                    autoComplete="company"
                    value={companyName}
                    onChange={e => setCompanyName(e.target.value)}
                    className="block w-full rounded-md border-0 bg-white/5 px-3.5 py-2 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
              <div className="sm:col-span-2">
                <label htmlFor="job title" className="block text-sm font-semibold leading-6 text-white">
                  Job Title
                </label>
                <div className="mt-2.5 w-full xs:mt-0.5">
                  <input
                    required
                    type="text"
                    name="job-title"
                    id="job-title"
                    autoComplete="job-title"
                    value={jobTitle}
                    onChange={e => setJobTitle(e.target.value)}
                    className="block w-full rounded-md border-0 bg-white/5 px-3.5 py-2 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
              <div className="sm:col-span-2">
                <label htmlFor="additional-info" className="block text-sm font-semibold leading-6 text-white">
                  Additional Info
                </label>
                <div className="mt-2.5 w-full xs:mt-0.5">
                  <textarea
                    name="additional-info"
                    id="additional-info"
                    value={additionalInfo}
                    onChange={e => setAdditionalInfo(e.target.value)}
                    className="block w-full rounded-md border-0 bg-white/5 px-3.5 py-2 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
            </div>
            <div className="mt-8 flex justify-end">
              <Cta
                type="submit"
                variant="primary"
                className="rounded-md bg-indigo-500 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
              >
                Submit
              </Cta>
            </div>
          </div>
          {formMessage && (
            <div className={formMessage.startsWith('Form submitted successfully') ? 'text-green-500' : 'text-red-500'}>
              {formMessage}
            </div>
          )}
        </form>
      </div>
    );
  }

  return (
    <Dialog open={isOpen} onOpenChange={closeModal}>
      <DialogContent className="flex max-w-[400px] rounded-2xl border-[#333741] bg-gray-900 px-8 py-[20px] md-lg:max-w-[600px]">
        <DialogClose
          onClick={closeModal}
          className="absolute right-4 top-4 z-[10000] rounded-sm opacity-70 ring-offset-background transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-accent data-[state=open]:text-muted-foreground"
        >
          <X className="h-8 w-8" color="white" onClick={closeModal} />
          <span className="sr-only">Close</span>
        </DialogClose>
        <div className="flex w-full bg-gray-900">{content}</div>
      </DialogContent>
    </Dialog>
  );
};
