import React from 'react';

const Tracer: React.FC<{ children: React.ReactNode; className: string }> = ({ children, className }) => {
  return (
    <div className={className}>
      <div className="flex h-[38px] items-center justify-center rounded-full bg-[#231E44] text-center font-poppins text-[16px] font-light leading-[24px] text-white">
        {children}
      </div>
    </div>
  );
};

export default Tracer;
