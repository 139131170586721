import React from 'react';
import Main from '../components/Main';
import Typography from '../components/Typography';

const CookiePolicyPage: React.FC = () => {
  return (
    <Main className="main-default">
      <div className="container mx-auto px-4 py-8">
        <Typography tag="h1" variant="display-md" mobile="display-md">
          Mithrl Cookie Policy
        </Typography>

        <Typography tag="span" variant="text-xs" className="block italic">
          Effective date: July 10, 2024
        </Typography>

        <div className="mb-6 space-y-4 text-gray-500 dark:text-gray-400">
          <Typography tag="p" variant="text-sm" className="mb-6 text-white">
            Mithrl believes in transparency about collection and use of data. This policy provides information about how
            and when Mithrl uses cookies for these purposes. Capitalized terms used in this policy but not defined have
            the meaning set forth in our{' '}
            <a className="text-[#AB22FF]" href="/privacy">
              Privacy Policy
            </a>
            , which also includes additional details about the collection and use of information at Mithrl.
          </Typography>

          <ol className="list-inside list-decimal">
            <Typography variant="text-md" tag="span">
              <li>What is a cookie?</li>
            </Typography>{' '}
            <Typography tag="p" variant="text-sm" className="mb-6 text-white">
              Cookies are small text files sent by us to your computer or mobile device, which enable Mithrl features
              and functionality. They are unique to your account or your browser. Session-based cookies last only while
              your browser is open and are automatically deleted when you close your browser. Persistent cookies last
              until you or your browser delete them or until they expire.
            </Typography>
            <Typography variant="text-md" tag="span">
              <li>Does Mithrl use cookies?</li>
            </Typography>{' '}
            <Typography tag="p" variant="text-sm" className="mb-6 text-white">
              Yes. Mithrl uses cookies and similar technologies like single-pixel gifs and web beacons. Mithrl uses both
              session-based and persistent cookies. Mithrl sets and accesses cookies on the domains operated by Mithrl
              and its corporate affiliates (collectively, the “Sites”). In addition, Mithrl uses third party cookies,
              like Amplitude.
            </Typography>
            <Typography variant="text-md" tag="span">
              <li>How is Mithrl using cookies?</li>
            </Typography>{' '}
            <Typography tag="p" variant="text-sm" className="mb-6 text-white">
              Some cookies are associated with your account and personal information to remember that you are logged in
              and which workspaces you are logged into. Other cookies are not tied to your account but are unique and
              allow us to carry out analytics and customization, among other similar things. Cookies can be used to
              recognize you when you visit a Site or use our Services, remember your preferences, and give you a
              personalized experience that is consistent with your settings. Cookies also make your interactions faster
              and more secure.
            </Typography>
            <Typography tag="p" variant="text-sm" className="mb-6 text-white">
              Categories of use:
            </Typography>
            <ul className="mt-2 list-inside list-disc space-y-4 ps-5">
              <li>
                <Typography variant="text-md" tag="span">
                  Authentication:
                </Typography>{' '}
                <Typography tag="p" variant="text-sm" className="mb-6 text-white">
                  If you're signed into the Services, cookies help Mithrl show you the right information and personalize
                  your experience.
                </Typography>
              </li>
              <li>
                <Typography variant="text-md" tag="span">
                  Security:
                </Typography>{' '}
                <Typography tag="p" variant="text-sm" className="mb-6 text-white">
                  Mithrl uses cookies to enable and support security features, and to help detect malicious activity.
                </Typography>
              </li>
              <li>
                <Typography variant="text-md" tag="span">
                  Preferences, features, and services:
                </Typography>{' '}
                <Typography tag="p" variant="text-sm" className="mb-6 text-white">
                  Cookies denote which language you prefer and what your communications preferences are. They can help
                  fill out forms on our Sites more easily. They also provide you with features, insights, and customized
                  content.
                </Typography>
              </li>
              <li>
                <Typography variant="text-md" tag="span">
                  Performance, Analytics, and Research:
                </Typography>{' '}
                <Typography tag="p" variant="text-sm" className="mb-6 text-white">
                  Cookies help Mithrl learn how well the Sites and Services perform. Mithrl also uses cookies to
                  understand, improve, and research products, features, and services, including to create logs and
                  record when you access our Sites and Services from different devices, such as your work computer or
                  your mobile device.
                </Typography>
              </li>
            </ul>
            <Typography variant="text-md" tag="span">
              <li>Does Mithrl respond to Do Not Track Signals?</li>
            </Typography>{' '}
            <Typography tag="p" variant="text-sm" className="mb-6 text-white">
              The Sites and Services do not collect personal information about your online activities over time and
              across third-party websites or online services. Therefore, “do not track” signals transmitted from web
              browsers do not apply to the Sites or Services, and Mithrl does not alter any data collection and use
              practices upon receipt of such a signal.
            </Typography>
          </ol>
        </div>
      </div>
    </Main>
  );
};

export default CookiePolicyPage;
