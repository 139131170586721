import React from 'react';
import clx from '../lib/classList';
import Header from '../components/Header';
import Footer from './Footer';

const Main = React.forwardRef<HTMLDivElement, React.ComponentProps<'main'>>(
  ({ children, className, ...props }, ref) => {
    return (
      <main ref={ref} className={clx('flex min-h-screen flex-col', className)} {...props}>
        <div className="stretch">
          <Header />

          <div className="content flex flex-1 flex-col">{children}</div>

          <Footer />
        </div>
      </main>
    );
  },
);

export default Main;
