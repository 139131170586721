import React from 'react';
import MQ from 'react-responsive';
import { Breakpoints } from '../constans';

type MediaQueryProps = {
  children: React.ComponentProps<typeof MQ>['children'];
  view?: 'xs' | 'md' | 'lg' | 'xs-md' | 'md-lg';
};

const MediaQuery: React.FC<MediaQueryProps> = ({ children, view }) => {
  switch (view) {
    case 'xs':
      return <MQ maxWidth={Breakpoints.md - 1}>{children}</MQ>;

    case 'xs-md':
      return <MQ maxWidth={Breakpoints.lg - 1}>{children}</MQ>;

    case 'md-lg':
      return <MQ minWidth={Breakpoints.md}>{children}</MQ>;

    case 'md':
      return (
        <MQ minWidth={Breakpoints.md} maxWidth={Breakpoints.lg - 1}>
          {children}
        </MQ>
      );

    case 'lg':
      return <MQ minWidth={Breakpoints.lg}>{children}</MQ>;

    default:
      return <>{children}</>;
  }
};

export default MediaQuery;
