import React, { useEffect, useRef, useState } from 'react';
import { Tabs, TabsList, TabsTrigger } from '../Tabs';
import { cn } from '../../lib/utils';
import { useBreakpoint } from '../../utils/useBreakpoint';

const captionHeader: Record<string, string> = {
  alignment: 'One-click alignment',
  survey: 'Instant literature survey',
  analysis: 'Generate analyses instantly',
};

const captionBody: Record<string, string> = {
  alignment:
    'Mithrl automatically understands the context of your experiment -- cleaning, processing, and aligning your FastQ files with a single click -- no software expertise required.',
  survey:
    'Mithrl conducts an in-depth literature survey and generates precise scientific questions tailored to your experiment, offering a hyper-personalized experience.',
  analysis:
    'Mithrl writes the code, creates visualizations, and delivers a research paper in minutes—all with zero manual effort.',
};

const floatingText: Record<string, string> = {
  alignment: 'Start',
  survey: 'Build',
  analysis: 'Analyze',
};

const headerStyles: Record<string, string> = {
  alignment: 'text-[#6A47B7]',
  survey: 'text-[#225EA6]',
  analysis: 'text-[#397885]',
};

const backgroundStyles: Record<string, string> = {
  alignment:
    'bg-[linear-gradient(0deg,_rgba(51,51,51,0)_0%,_rgba(83,56,158,0.44)_25%,_rgba(83,56,158,0.44)_50%,_rgba(83,56,158,0.26)_75%,_rgba(51,51,51,0)_100%)]',
  survey:
    'bg-[linear-gradient(0deg,_rgba(51,51,51,0)_0%,_rgba(9,44,86,0.44)_25%,_rgba(9,44,86,0.74)_50%,_rgba(9,44,86,0.26)_75%,_rgba(51,51,51,0)_100%)]',
  analysis:
    'bg-[linear-gradient(0deg,_rgba(51,51,51,0)_0%,_rgba(28,67,76,0.44)_25%,_rgba(28,67,76,0.74)_50%,_rgba(28,67,76,0.26)_75%,_rgba(51,51,51,0)_100%)]',
};

const floatingStyles: Record<string, string> = {
  alignment: 'bg-gradient-to-r from-[#7F56D9] to-[#A340E0] left-[178px]',
  survey: 'bg-gradient-to-r from-[#225EA6] to-[#3688E9] left-[178px]',
  analysis: 'bg-gradient-to-r from-[#225EA6] to-[#50B3C4] left-[80px]',
};

const gifSource: Record<string, string> = {
  alignment: 'gifs/alignment_demo.gif',
  survey: 'gifs/survey_demo.gif',
  analysis: 'gifs/analysis_demo.gif',
};

const HomeLaptopVideos = () => {
  const [selectedTab, setSelectedTab] = useState('alignment');
  const [containerWidth, setContainerWidth] = useState(0);
  const elRef = useRef<HTMLDivElement>(null);
  const breakpoint = useBreakpoint();

  useEffect(() => {
    const el = elRef.current;
    if (!el) return;

    const resizeObserver = new ResizeObserver(entries => {
      for (let _ of entries) {
        setContainerWidth(Math.min(1200, window.innerWidth));
      }
    });

    resizeObserver.observe(el);

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  const bgStyles = backgroundStyles[selectedTab];
  const header = captionHeader[selectedTab];
  const hStyles = headerStyles[selectedTab];
  const body = captionBody[selectedTab];
  const gifSrc = gifSource[selectedTab];

  let content = null;
  const floatingContent = (
    <React.Fragment>
      {Object.keys(floatingText).map(key => (
        <div
          key={key}
          className={cn(
            'absolute left-none top-[-86px] bg-clip-text text-[80px] font-semibold leading-[136px] text-transparent opacity-20 mix-blend-lighten transition-all duration-300 md:top-[-90px] md:text-[136px] lg:left-none lg:top-[-120%]',
            floatingStyles[key],
            key === selectedTab ? '' : 'animate-slideLeftFade',
          )}
        >
          {floatingText[key]}
        </div>
      ))}
    </React.Fragment>
  );
  const textContent = (
    <div className="flex h-fit flex-col items-center gap-10 text-left">
      <div className="relative flex flex-col items-start gap-10 text-left xl:max-w-[400px]">
        <div className={cn('font-poppins text-[32px] font-semibold leading-[44px]', hStyles)}>{header}</div>
        <div className="font-poppins text-[18px] font-normal leading-[28px] text-[#ECECED]">{body}</div>
        {breakpoint === 'computer' ? floatingContent : null}
      </div>
    </div>
  );
  let contentWidth = `${containerWidth}px`;
  let contentHeight = '100%';
  if (breakpoint !== 'mobile') {
    contentWidth = `${Math.min(containerWidth * 0.7, 1200)}px`;
  }
  const displayContent = (
    <div className="relative flex w-fit flex-col items-center">
      {breakpoint !== 'computer' ? floatingContent : null}
      <div
        style={{ borderWidth: '0.665px', borderColor: 'rgba(255, 255, 255, 0.20)', width: contentWidth }}
        className="2hadow-[-592px_635px_243px_0_rgba(45,24,92,0.01),-379px_407px_222px_0_rgba(45,24,92,0.07),-213px_229px_188px_0_rgba(45,24,92,0.23),-95px_102px_139px_0_rgba(45,24,92,0.39),-24px_25px_76px_0_rgba(45,24,92,0.45)] z-[1] flex flex-col items-center justify-center gap-2 rounded-3xl bg-[#ffffff1a] p-2 pb-3"
      >
        <div className="flex-start flex w-full justify-start gap-2 pb-[8px] pl-[6px] pt-[6px]">
          <img src="assets/icons/dot.svg" alt="dot" />
          <img src="assets/icons/dot.svg" alt="dot" />
          <img src="assets/icons/dot.svg" alt="dot" />
        </div>
        <div className="z-[1] h-full w-full overflow-hidden rounded-xl bg-white shadow-[inset_0px_0px_1.229px_0.615px_rgba(0,0,0,0.40)]">
          <img src={gifSrc} alt="demo gif" width={contentWidth} height={contentHeight} />
        </div>
      </div>
    </div>
  );

  let flexProperties = 'w-full';
  if (breakpoint === 'mobile') {
    if (selectedTab === 'alignment') {
      flexProperties = 'w-fit self-start';
    } else if (selectedTab === 'analysis') {
      flexProperties = 'w-fit self-end';
    } else {
      flexProperties = 'w-fit';
    }
  }

  content = (
    <React.Fragment>
      <div
        className={cn(
          'flex h-fit w-full items-center justify-center gap-8 px-[1rem] pb-[60px]',
          breakpoint === 'computer' ? '' : 'flex-col',
        )}
      >
        {breakpoint === 'computer' ? (
          <React.Fragment>
            {textContent}
            {displayContent}
          </React.Fragment>
        ) : (
          <React.Fragment>
            {displayContent}
            {textContent}
          </React.Fragment>
        )}
      </div>
      <Tabs
        className={cn('flex h-[44px] max-w-[1300px]', flexProperties)}
        defaultValue="alignment"
        value={selectedTab}
        onValueChange={tab => {
          setSelectedTab(tab);
        }}
      >
        <TabsList className="h-full w-full">
          <TabsTrigger className="h-full flex-grow" value="alignment">
            One-click Alignment
          </TabsTrigger>
          <TabsTrigger className="flex-grow" value="survey">
            Instant literature survey
          </TabsTrigger>
          <TabsTrigger className="flex-grow" value="analysis">
            Generate analyses instantly
          </TabsTrigger>
        </TabsList>
      </Tabs>
    </React.Fragment>
  );

  return (
    <div ref={elRef} className={cn('relative flex h-screen w-full flex-col items-center justify-center px-[1rem]')}>
      <div className={cn(bgStyles, 'absolute z-[-1] h-[90%] w-full animate-fadeIn')} />
      {content}
    </div>
  );
};

export default HomeLaptopVideos;
