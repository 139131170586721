import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Nav from './Nav';
import MediaQuery from './MediaQuery';
import Typography from '../components/Typography';
import { MenuIcon } from '../icons';
import Menu from './Menu';
import Cta from './Cta';

const Header: React.FC = () => {
  const [open, setOpen] = React.useState(false);
  const location = useLocation();
  const navigate = useNavigate(); // Initialize navigate

  React.useEffect(() => {
    setOpen(false);
  }, [location]);

  return (
    <header className="z-10 lg:px-none">
      {/* Banner with onClick event to navigate to /news */}
      <div
        className="flex h-full w-full items-center justify-center bg-violet-900 text-center cursor-pointer"
        onClick={() => navigate('/news/mithrl-fundraise-announcement-2024')}

      >
        <Typography
          tag="span"
          variant="text-xs"
          className="container flex h-fit w-full items-center justify-center text-center text-sm text-white font-semibold py-6"
        >
          🎉 Mithrl raises $4 Million in new funding! Learn more about our mission and impact.
        </Typography>
      </div>
      <div className="container flex items-center justify-between py-[2rem] lg:pr-[2.6rem] xs-md:pt-[1.8rem]">
        <Nav />
        <MediaQuery view="xs-md">
          <button className="px-[0.4rem] py-sm md:-mr-xs">
            <MenuIcon className="h-[2.4rem] w-[2.4rem] text-gray-200" onClick={() => setOpen(open => !open)} />
          </button>
          <Menu open={open} setOpen={setOpen} />
        </MediaQuery>
        <MediaQuery view="lg">
          <div className="flex items-center gap-[1.2rem]">
            <Cta variant="primary" tag="link" to="https://login.mithrl.com" target="_blank">
              Log in
            </Cta>
            <Cta
              data-cal-namespace="mithrl-demo"
              data-cal-link="mithrl/mithrl-demo"
              data-cal-config='{"layout":"month_view","theme":"dark"}'
            >
              Book a Demo
            </Cta>
          </div>
        </MediaQuery>
      </div>
    </header>
  );
};

export default Header;
