import React from 'react';
import { DribbleIcon, LinkedinIcon, TwitterIcon } from '../../icons';
import clx from '../../lib/classList';

type ProfileCardProps = {
  name: string;
  position: string;
  image?: string;
  linkedIn?: string;
  twitter?: string;
  dribble?: string;
};

const ProfileCard: React.FC<ProfileCardProps> = ({ name, position, image, linkedIn, twitter, dribble }) => (
  <div className="flex min-w-none flex-col gap-3xl">
    <div
      className={clx('h-[20rem] w-full rounded-md bg-purple-950 bg-cover bg-center', {
        'border border-purple-800': !image,
      })}
      style={{
        backgroundImage: `url(${image})`,
      }}
    />
    <div>
      <p className="text-xl font-semibold leading-normal text-white">{name}</p>
      <p className="text-lg leading-normal text-gray-400">{position}</p>
    </div>
    <div className="flex gap-14">
      {linkedIn && (
        <a href={linkedIn} target="_blank" rel="nofollow">
          <LinkedinIcon className="h-[2.4rem] w-[2.4rem] text-white" />
        </a>
      )}

      {twitter && (
        <a href={twitter} target="_blank" rel="nofollow">
          <TwitterIcon className="h-[2.4rem] w-[2.4rem] text-white" />
        </a>
      )}

      {dribble && (
        <a href={dribble} target="_blank" rel="nofollow">
          <DribbleIcon className="h-[2.4rem] w-[2.4rem] text-white" />
        </a>
      )}
    </div>
  </div>
);

export default ProfileCard;
