import React, { useState } from 'react';
import Main from '../components/Main';
import PriceTier from '../components/pricing/PriceTier';
import Typography from '../components/Typography';
import { HatIcon } from '../icons';
import { ContactUsForm } from '../components/ContactUs';
import Cta from '../components/Cta';
import ScrollAnimation from 'react-animate-on-scroll';

const FOUNDATION_PLAN = [
  'Fully managed cloud',
  '3 samples/month processing',
  '1 user/organization',
  '5 analyses/month',
  'Academic institutions and startups with less than $5M in funding',
];

const CATALYST_PLAN = [
  'Fully managed, secure cloud',
  '100 samples/month processing',
  'Unlimited analyses and users',
  'Shared company projects',
  <span className="font-bold">Dedicated Support</span>,
  '8/5 support via Slack and Teams',
];

const ENTERPRISE_PLAN = [
  'Enterprise-grade, dedicated cloud environment',
  'Unlimited samples processing',
  'Unlimited users/organization',
  'Unlimited analyses',
  'Shared company projects with configurable access',
  '24/7 support via Slack and Teams',
  'Access to a dedicated bioinformatics team',
];

const Pricing: React.FC = () => {
  const [showForm, setShowForm] = useState(false);
  return (
    <Main className="main-home">
      <div className="main-home pt-6xl">
        <ScrollAnimation animateIn="fadeInUp" animateOnce>
          <div className="flex flex-col items-center gap-2xl xs:gap-xl xs:px-xl xs:text-center">
            <Typography tag="h1" variant="display-md">
              Pricing
            </Typography>
            <Typography tag="h2" variant="text-lg">
              Designed for every stage of your journey.
            </Typography>
          </div>
        </ScrollAnimation>

        <div className="container xs:pt-6xl md-lg:py-7xl">
          <div className="lg:px-[4.8rem]">
            <ScrollAnimation animateIn="fadeInUp" animateOnce>
              <div className="grid items-center gap-lg pb-6xl xs:gap-xl md-lg:grid-cols-3">
                <PriceTier features={FOUNDATION_PLAN} title="Foundation" highlight={false} index={0}>
                  <Cta
                    data-cal-namespace="mithrl-demo"
                    data-cal-link="mithrl/mithrl-demo"
                    data-cal-config='{"layout":"month_view","theme":"dark"}'
                    size="lg"
                    variant="primary"
                    className="mt-xxs w-full !py-[1rem]"
                  >
                    Get started
                  </Cta>
                </PriceTier>

                <PriceTier features={CATALYST_PLAN} title="Catalyst" highlight={true} index={1}>
                  <Cta
                    data-cal-namespace="mithrl-demo"
                    data-cal-link="mithrl/mithrl-demo"
                    data-cal-config='{"layout":"month_view","theme":"dark"}'
                    size="lg"
                    className="w-full"
                  >
                    Get started
                  </Cta>
                </PriceTier>

                <PriceTier features={ENTERPRISE_PLAN} title="Enterprise plan" highlight={false} index={2}>
                  <Cta
                    onClick={() => setShowForm(true)}
                    size="lg"
                    variant="primary"
                    className="mt-xxs w-full !py-[1rem]"
                  >
                    Contact Sales
                  </Cta>
                </PriceTier>
              </div>
            </ScrollAnimation>
            <ScrollAnimation animateIn="fadeInUp" animateOnce>
              <div className="flex items-start gap-2xl">
                <div className="shrink-0">
                  <HatIcon />
                </div>
                <div className="grid flex-1 gap-md">
                  <Typography tag="h4" variant="text-xl" className="text-white">
                    Academics
                  </Typography>
                  <Typography tag="p" variant="text-md">
                    Academics are eligible for free access to the Mithrl platform for academic research, subject to
                    request and approval. To get started, contact us at{' '}
                    <a href="mailto:platform@mithrl.com" className="text-white hover:underline">
                      platform@mithrl.com
                    </a>
                    .
                  </Typography>
                </div>
              </div>
              <ContactUsForm
                isOpen={showForm}
                onClose={() => {
                  setShowForm(false);
                }}
              />
            </ScrollAnimation>
          </div>
        </div>
      </div>
    </Main>
  );
};

export default Pricing;
