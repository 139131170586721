import React from 'react';
import { Link } from 'react-router-dom';
import MediaQuery from './MediaQuery';
import { LogoIcon } from '../icons';

type NavLogoProps = {
  size?: number;
};

const NavLogo: React.FC<NavLogoProps> = ({ size = 1 }) => (
  <Link to="/">
    <LogoIcon style={{ width: '13.4em', height: '2.9em', fontSize: `${size}rem` }} />
  </Link>
);

export default NavLogo;
