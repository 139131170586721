import React from 'react';
import clx from '../../lib/classList';
import { CheckIcon, RadioCheckedIcon } from '../../icons';
import Button from '../Button';
import Typography from '../Typography';
import { CircleCheck } from 'lucide-react';

type PriceTierProps = React.PropsWithChildren<{
  title: React.ReactNode;
  highlight?: boolean;
  features: (string | React.ReactNode)[];
  index?: number;
}>;

const PriceTier: React.FC<PriceTierProps> = ({ title, highlight, features, children, index }) => {
  return (
    <div
      className={clx(
        'flex min-h-[600px] flex-col rounded-[1.6rem] border border-gray-900 bg-gray-900 transition-all duration-300',
        {
          'z-10 scale-105 border-purple-600 bg-purple-900': highlight,
          'scale-95 opacity-75': !highlight && highlight !== undefined,
          'bg-gradient-to-l from-[#090d14] to-transparent': index === 0,
          'bg-gradient-to-r from-[#090d14] to-transparent': index === 2,
        },
      )}
    >
      <div
        className={clx('border-b lg:px-[2rem] lg:py-[2rem]', 'md:px-lg md:py-[2rem]', 'xs:px-xl xs:py-3xl', {
          'border-gray-800': !highlight,
          'border-gray-600': highlight,
          'border-purple-600': highlight,
        })}
      >
        <div className="flex items-center justify-between gap-xl rounded-[1rem] shadow-sm md:lg:p-[0.3rem] xs:gap-xl xs-md:flex-col">
          <span
            className={clx(
              'flex items-center gap-sm rounded-[1rem] border border-purple-950 bg-gradientD px-[1.1rem]',
              'py-[0.5rem] text-lg font-medium leading-5 text-white shadow-sm',
            )}
          >
            {highlight && <RadioCheckedIcon className="-mx-xxs h-[1.2rem] w-[1.3rem] text-purple-700 shadow-sm" />}
            {title}
          </span>
          <span
            className={clx('text-base font-medium leading-5 text-green-500', {
              'pointer-events-none opacity-0': !highlight,
            })}
          >
            Most popular
          </span>
        </div>
      </div>
      <div className="flex-1 px-[2rem] pt-[4rem] md:px-[1.5rem] md:pt-4 xs:px-4 xs:pt-4">
        <ul className="grid list-outside list-none gap-[2rem] pl-[1.5rem] text-white">
          {features.map((feature, index) => (
            <li key={index} className="flex items-center gap-3">
              <CircleCheck size={14} className="flex-shrink-0" />
              <Typography tag="span" variant="text-md" className={'text-white'}>
                {feature}
              </Typography>
            </li>
          ))}
        </ul>
      </div>
      <div className="mt-auto px-4 py-6 xs:px-4">{children}</div>
    </div>
  );
};

export default PriceTier;
