import React from 'react';
import MediaQuery from './MediaQuery';
import NavLogo from './NavLogo';
import MenuItems from './MenuItems';

const Nav: React.FC = () => (
  <nav className="flex items-center gap-[4rem]">
    <MediaQuery view="lg">{lg => <NavLogo size={lg ? 1 : 1.15} />}</MediaQuery>
    <MediaQuery view="lg">
      <MenuItems />
    </MediaQuery>
  </nav>
);

export default Nav;
