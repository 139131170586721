import React, { useEffect, useRef, useState } from 'react';
import { gsap } from 'gsap';
import { cn } from '../../lib/utils';
import ScrollAnimation from 'react-animate-on-scroll';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { useWindowSize } from '../../utils/useWindowSize';

gsap.registerPlugin(ScrollTrigger);

const ExpandingVideo = () => {
  const divRef = useRef(null);
  const logoRef = useRef(null);
  const [containerWidth, setContainerWidth] = useState(0);
  const [showGif, setShowGif] = useState(false);

  useEffect(() => {
    const el = divRef.current;
    if (!el) return;

    const resizeObserver = new ResizeObserver(entries => {
      for (let entry of entries) {
        setShowGif(entry.contentRect.top < window.innerHeight * 0.7);
        setContainerWidth(Math.min(1200, window.innerWidth));
      }
    });

    resizeObserver.observe(el);

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  return (
    <ScrollAnimation animateIn="fadeInUp" animateOnce delay={250}>
      <div
        ref={divRef}
        className="tight-container flex h-full items-center justify-center"
        style={{ width: containerWidth }}
      >
        <div
          className={'z-[2] flex h-full flex-grow items-center justify-center p-8'}
          style={{ width: containerWidth }}
        >
          <div
            className={cn(
              'relative z-[2] m-4 flex flex-grow items-center justify-center rounded-3xl',
              'lg:rounded-3xl',
              'md:rounded-[1.8rem]',
              'z-[2] xs:rounded-[0.9rem]',
            )}
            style={{ width: containerWidth }}
          >
            {showGif ? (
              <img
                src="gifs/main_mithrl_demo.gif"
                alt="demo gif"
                className="z-[2] h-full rounded-3xl"
                style={{ width: containerWidth }}
              />
            ) : (
              <img
                src="assets/images/main_mithrl_demo_still.jpg"
                alt="demo gif"
                className="z-[2] h-full rounded-3xl"
                style={{ width: containerWidth }}
              />
            )}
          </div>
        </div>
      </div>
    </ScrollAnimation>
  );
};

export default ExpandingVideo;
