import React, { useState } from 'react';
import { ShieldIcon, TenXIcon } from '../../icons';
import ScrollAnimation from 'react-animate-on-scroll';
import clx from '../../lib/classList';
import Typography from '../Typography';
import HomeLaptopVideos from './HomeLaptopVideos';
import Cta from '../Cta';
import Tracer from '../Tracer';
import { cn } from '../../lib/utils';
import { useBreakpoint } from '../../utils/useBreakpoint';
import { useWindowSize } from '../../utils/useWindowSize';
import { ContactUsForm } from '../ContactUs';

const HomeFeatures: React.FC = () => {
  const [showForm, setShowForm] = useState(false);
  const [width, _] = useWindowSize();
  const breakpoint = useBreakpoint();
  let tenIconWidth = 967;
  let tenIconHeight = 493;
  if (breakpoint !== 'computer') {
    tenIconWidth = width * 0.9;
    tenIconHeight = tenIconWidth * 0.5;
  }
  let blurWidth = 349;
  let blurRight = 8;
  let blurTop = 0;
  if (breakpoint === 'mobile') {
    blurWidth = width * 0.9;
    blurRight = 0;
    blurTop = -5;
  }

  return (
    <div className="relative z-10 overflow-x-hidden bg-gradientC">
      <ScrollAnimation animateIn="fadeInUp" animateOnce>
        <div className="container">
          <div className="pb-[3.5rem] pt-[9.2rem] md:pt-[4.4rem] xs:pt-48 xs-md:pb-48">
            <div
              className={clx(
                'mx-auto flex w-full flex-col md-lg:max-w-[76.8rem] md-lg:items-center',
                'xs:items-center xs:text-center',
              )}
            >
              <ShieldIcon className="aspect-square h-[5.6rem]" />
              <Typography
                tag="h3"
                variant="display-md"
                mobile="display-xs"
                className="mt-[1.2rem] font-normal xs:mt-lg xs:tracking-wide md-lg:text-center"
              >
                Trusted by industry-leading companies around the globe
              </Typography>
              <Typography
                tag="h4"
                variant="text-lg"
                className="mt-2xl bg-gradient-to-r from-[#7F56D9] to-[#A340E0] bg-clip-text text-transparent md-lg:text-center"
              >
                For scientists to supercharge their power.
              </Typography>
            </div>
          </div>
        </div>
      </ScrollAnimation>

      <div className="flex h-full w-full items-center justify-center">
        <div
          className="my-[100px] flex h-[3px] w-[50%]"
          style={{
            background: 'linear-gradient(to right, rgb(0, 0, 0), rgb(255, 255, 255), rgb(0, 0, 0))',
          }}
        />
      </div>

      <div className="mt-[1.2rem] py-[9.6rem] text-center xs:pt-48">
        <ScrollAnimation animateIn="fadeInUp" animateOnce>
          <div className="flex items-center justify-center">
            <Tracer className="how-it-works-tracer">How it works</Tracer>
          </div>
          <Typography tag="h3" variant="display-md" mobile="display-sm" className="mt-lg md:px-[7.6rem]">
            Streamline Your
          </Typography>
          <Typography
            tag="h3"
            variant="display-md"
            mobile="display-sm"
            className="mt-lg bg-gradient-to-r from-[#7F56D9] to-[#A340E0] bg-clip-text text-transparent md:px-[7.6rem]"
          >
            NGS Data Lifecycle with Mithrl
          </Typography>

          <div className="flex justify-center pt-2xl">
            <Typography tag="h4" variant="text-xl" className="pb-xxs font-semibold text-white">
              You are always in control.
            </Typography>
          </div>
          <div className="tight-container mt-14">
            <Typography tag="h3" variant="text-lg" className="text-[#ECECED]">
              Mithrl helps you transform raw NGS data into actionable insights. From data ingestion and storage to
              comprehensive analysis, the AI assistant automates complex workflows to deliver rapid, precise results --
              in minutes
            </Typography>
          </div>
        </ScrollAnimation>
      </div>

      <div className="mt-[1.2rem] py-[9.6rem] text-center xs:pt-48">
        <ScrollAnimation animateIn="fadeInUp" animateOnce>
          <HomeLaptopVideos />
        </ScrollAnimation>
      </div>

      <ScrollAnimation animateIn="fadeInUp" animateOnce>
        <div className="flex flex-col items-center justify-center px-[1.2rem] pb-[40px]">
          <div
            className="relative flex flex-col items-center justify-center gap-2 text-center"
            style={{ height: `${tenIconHeight}px` }}
          >
            <Typography
              tag="h2"
              variant="text-xl"
              className="text-[24px] font-semibold leading-[20px] text-white md:text-[48px] md-lg:text-[36px] md-lg:leading-[58px]"
            >
              {'Mithrl provides your R&D Teams'}
            </Typography>
            <Typography
              tag="h2"
              variant="text-lg"
              className="bg-gradient-to-r from-[#7F56D9] to-[#A340E0] bg-clip-text font-poppins text-[24px] font-semibold leading-[20px] tracking-[-0.96px] text-transparent md:text-[48px] md-lg:text-[36px] md-lg:leading-[58px]"
            >
              with a 10x return on investment.
            </Typography>
          </div>
          <TenXIcon className="absolute" width={`${tenIconWidth}px`} height={`${tenIconHeight}px`} />
          <div
            className="absolute z-[-1] rounded-full bg-[#6941C6] opacity-[0.3] blur-[150px]"
            style={{ width: blurWidth, height: blurWidth, right: `${blurRight}%`, top: `${blurTop}%` }}
          />
          {breakpoint !== 'mobile' ? (
            <div className="absolute left-[8%] z-[-1] h-[587px] w-[587px] rounded-full bg-[#A242E019] blur-[134px]" />
          ) : null}
        </div>
        <div className="mb-[10rem] flex flex-col items-center justify-center px-[1.2rem] text-center">
          <Typography tag="h4" variant="text-lg" className="mb-[40px] text-[20px] text-[#F0F1F1]">
            {'Take the first step in transforming your R&D team.'}
          </Typography>
          <div className="flex items-center justify-center px-[22px] py-[16px]">
            <Cta size="lg" onClick={() => setShowForm(true)}>
              Contact Sales
            </Cta>
          </div>
        </div>
        <ContactUsForm
          isOpen={showForm}
          onClose={() => {
            setShowForm(false);
          }}
        />
      </ScrollAnimation>
    </div>
  );
};

export default HomeFeatures;
