import clsx from 'clsx';
import { extendTailwindMerge } from 'tailwind-merge';

const twMerge = extendTailwindMerge({
  extend: {
    classGroups: {
      'bg-image': ['bg-homeHero', 'bg-homeFeatures', 'bg-pricingLightAccent'],
      'gap-x': ['gap-sm', 'gap-md'],
    },
  },
});

export function cn(...inputs: clsx.ClassValue[]) {
  return twMerge(clsx(...inputs));
}
