import React, { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import Pricing from './pages/Pricing';
import News from './pages/News';
import About from './pages/About';
import Article from './components/news/Article';
import TermsPage from './pages/Terms';
import PrivacyPage from './pages/Privacy';
import CookiePolicyPage from './pages/CookiePolicy';
import './index.css';
import { getCalApi } from '@calcom/embed-react';
import { useRB2B } from './utils/useRB2B';

function App() {
  useRB2B();

  useEffect(() => {
    (async function () {
      const cal = await getCalApi({ namespace: 'mithrl-demo' });
      cal('ui', { styles: { branding: { brandColor: '#000000' } }, hideEventTypeDetails: true, layout: 'month_view' });
    })();
  }, []);

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/pricing" element={<Pricing />} />
      <Route path="/about" element={<About />} />
      <Route path="/terms" element={<TermsPage />} />
      <Route path="/privacy" element={<PrivacyPage />} />
      <Route path="/cookie-policy" element={<CookiePolicyPage />} />
      <Route path="/news">
        <Route index element={<News />} />
        <Route path="/news/:id" element={<Article />} /> // A dynamic child route under "/news" for displaying individual articles based on their unique ID
      </Route>
    </Routes>
  );
}
export default App;
