import React from 'react';
import Typography from '../components/Typography';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import NewsCard from '../components/news/NewsCard';
import Main from '../components/Main';
import ScrollAnimation from 'react-animate-on-scroll';

const News: React.FC = () => {
  return (
    <Main className="main-default">
      <div className="main-default container lg:py-3xl lg:pb-7xl xs-md:pb-6xl">
        <ScrollAnimation animateIn="fadeInUp" animateOnce>
          <div className="grid gap-3xl xs-md:pb-3xl xs-md:pt-6xl">
            <div className="grid shrink-0 gap-lg lg:w-[38.9rem]">
              <Typography tag="h2" variant="text-md" mobile="text-sm" className="gradient-text font-semibold">
                Our news and announcements
              </Typography>
              <Typography tag="h1" variant="display-md" mobile="display-md">
                News
              </Typography>
            </div>

            {/* Card container with flex-col for vertical stacking */}
            <div className="flex flex-col gap-6xl xs-md:w-full">
              {/* First Blog Card */}
              <Link
                  to="/news/mithrl-fundraise-announcement-2024"
                  className={clsx(
                    'relative flex items-end rounded-2xl border border-gray-800 bg-100 shadow-lg hover:bg-110 lg:flex-1',
                    'min-h-[30vh] bg-[#0D121E] bg-center-top bg-no-repeat transition-all duration-500 hover:border-purple-900 xs-md:bg-newsBlogMobile'
                  )}
                  style={{
                    backgroundImage: `url('/assets/images/blog/mithrl-fundraise-announcement-2024/Cover.png')`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                  }}
                >
                  {/* Overlay using pseudo-element */}
                  <span className="absolute bottom-0 left-0 right-0 h-1/3 bg-gradient-to-t from-black/80 to-transparent pointer-events-none rounded-b-2xl"></span>

                  {/* Text content */}
                  <span className="relative grid gap-md px-3xl lg:pb-[6.1rem] xs-md:pb-2xl pt-8 lg:pt-12 z-10">
                    <Typography tag="span" variant="text-md" className="block text-white">
                      News
                    </Typography>
                    <Typography tag="span" variant="text-xl" className="block font-semibold text-white">
                      Announcing our $4 million fundraise to accelerate scientific discovery and breakthroughs
                    </Typography>
                  </span>
                </Link>


              {/* Second Blog Card */}
              <Link
                to="/news/mithrl-in-top50-vertical-saas"
                className={clsx(
                  'flex items-end rounded-2xl border border-gray-800 bg-100 shadow-lg hover:bg-110 lg:flex-1 lg:bg-newsBlog xs-md:w-full',
                  'min-h-[30vh] bg-[#0D121E] bg-center-top bg-no-repeat transition-all duration-500 hover:border-purple-900 xs-md:bg-newsBlogMobile',
                )}
              >
                <span className="grid gap-md px-3xl lg:pb-[6.1rem] xs-md:pb-2xl">
                  <Typography tag="span" variant="text-md" className="block">
                    News
                  </Typography>
                  <Typography tag="span" variant="text-xl" className="block font-semibold text-white">
                    Mithrl honored with the 2024 Vertical SaaS 50!
                  </Typography>
                </span>
              </Link>

              {/* Third Blog Card */}
            </div>
          </div>
        </ScrollAnimation>
      </div>
    </Main>
  );
};

export default News;
