import React from 'react';
import Typography from '../components/Typography';
import ProfileCard from '../components/about/ProfileCard';
import Main from '../components/Main';
import ScrollAnimation from 'react-animate-on-scroll';

type CompanyType = {
  image: string;
  name: string;
  linkedIn: string;
  position: string;
};

const company: CompanyType[] = [
  {
    image: '/assets/images/company/vivek.jpg',
    linkedIn: 'https://www.linkedin.com/in/vivekadarsh/',
    name: 'Vivek Adarsh',
    position: 'Co-founder & CEO',
  },
  {
    image: '/assets/images/company/shara.jpg',
    linkedIn: 'https://www.linkedin.com/in/shara-19/',
    name: 'Shara Balakrishnan',
    position: 'Co-founder & CTO',
  },
  {
    image: '/assets/images/company/aqib.jpg',
    linkedIn: 'https://www.linkedin.com/in/aqib-hasnain-9074a61b7/',
    name: 'Aqib Hasnain',
    position: 'Lead Scientist',
  },
  {
    image: '/assets/images/company/brendan.jpg',
    linkedIn: 'https://www.linkedin.com/in/brendanshanny/',
    name: 'Brendan Shanny',
    position: 'Founding Engineer',
  },
  {
    image: '/assets/images/company/eugene.jpg',
    linkedIn: 'https://www.linkedin.com/in/eugene-gimelberg-6764424/',
    name: 'Eugene Gimelberg',
    position: 'Founding Engineer',
  },
  {
    image: '/assets/images/company/mikhail.jpg',
    linkedIn: 'https://www.linkedin.com/in/mikhail-maksimov-phd-b20a1919/',
    name: 'Mikhail Maksimov',
    position: 'Data Scientist',
  },
  {
    image: '/assets/images/company/rachel.jpg',
    linkedIn: 'https://www.linkedin.com/in/rachel-m-van-der-lugt/',
    name: 'Rachel van der Lugt',
    position: 'Business Development',
  },
  {
    image: '/assets/images/company/michael.jpg',
    linkedIn: 'https://www.linkedin.com/in/michael-linsner/',
    name: 'Michael Linsner',
    position: 'Business Development',
  },
  {
    image: '/assets/images/company/juliano.jpg',
    linkedIn: 'https://www.linkedin.com/in/julianodill/',
    name: 'Juliano Dill',
    position: 'Product Designer',
  },
].sort((a: CompanyType, b: CompanyType) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));

const About: React.FC = () => {
  return (
    <Main className="company-page main-transparent">
      <div className="pt-6xl md:pb-7xl lg:pb-8xl">
        <ScrollAnimation animateIn="fadeInUp" animateOnce>
          <Typography tag="h1" variant="display-md" mobile="display-sm" className="text-center">
            The AI Platform for Biology
          </Typography>
        </ScrollAnimation>
        <div className="pt-7xl xs:pt-6xl">
          <div className="mx-auto md:px-4xl xs:px-xl md-lg:max-w-[76.8rem]">
            <ScrollAnimation animateIn="fadeInUp" animateOnce>
              <Typography tag="h3" variant="text-md" className="gradient-text font-semibold">
                Pushing the boundaries.
              </Typography>

              <Typography
                tag="h2"
                variant="display-md"
                className="mt-lg tracking-normal xs:text-3xl xs:leading-[3.8rem]"
              >
                Scientists building for scientists
              </Typography>
              <p className="typography-text-lg mt-2xl text-gray-400">
                Founded by scientists who have lived experiences, our team consists of biologists, engineers and AI
                scientists. Our interdisciplinary backgrounds allow us to tackle complex biological challenges from
                multiple perspectives, pushing the boundaries of scientific discovery. Join us in shaping the future of
                biology using AI.
              </p>
            </ScrollAnimation>

            <ScrollAnimation animateIn="fadeInUp" animateOnce>
              <div className="pt-6xl">
                <Typography
                  tag="h3"
                  variant="text-md"
                  className="gradient-text border-b border-gray-700 pb-[1rem] font-semibold"
                >
                  Our Team
                </Typography>

                <div className="grid min-w-none gap-4xl pt-4xl xs:flex-col md-lg:grid-cols-3">
                  {company.map((data: CompanyType) => (
                    <ProfileCard
                      key={data.name}
                      image={data.image}
                      linkedIn={data.linkedIn}
                      name={data.name}
                      position={data.position}
                    />
                  ))}
                </div>
              </div>
            </ScrollAnimation>
          </div>
        </div>
      </div>
    </Main>
  );
};

export default About;
