import React from 'react';
import HomeHero from '../components/home/HomeHero';
import HomeFeatures from '../components/home/HomeFeatures';
import Main from '../components/Main';
import ExpandingVideo from '../components/home/ExpandingVideo';

const Home: React.FC = () => {
  return (
    <Main className="main-home">
      <HomeHero />
      <ExpandingVideo />
      <HomeFeatures />
    </Main>
  );
};

export default Home;
